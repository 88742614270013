import React from 'react';
import "./assets/css/app.css";
import {Layout, Menu, Button, Row, Col, Radio, Slider, InputNumber, Select} from 'antd';
import esriLoader from 'esri-loader';
import {MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, VideoCameraOutlined, UploadOutlined,} from '@ant-design/icons';
import jsonp from 'fetch-jsonp';

const {Header,Sider, Content, Footer} = Layout;
const basemapOptions = [
    {label: 'TopoVector', value: 'topo-vector'},
    {label: 'Streets', value: 'streets'},
    {label: 'Hybrid', value: 'hybrid'}
];
const {Option} = Select;
let timeout;
let currentValue;
let lonlat = [118.715383,32.203407];

function fetch(value, callback){
    if(timeout){
        clearTimeout(timeout);
        timeout = null
    }
    currentValue = value;

    function fake(){
        const url = 'https://restapi.amap.com/v3/geocode/geo?address=' + value + '&output=JSON&key=7ddea2ed171d78684ebcdd1e7f4a811a'
        jsonp(url).then(function(response){
            return response.json()
        }).then(d => {
            if (currentValue === value){
                const result = d.geocodes;
                const data = [];
                result.forEach( r => {
                    data.push({
                        value: r.adcode,
                        text: r.formatted_address
                    });
                    lonlat = r.location.split(',').map(Number)
                });
                callback(data);
            }
        });
    }
    timeout = setTimeout(fake, 300);
}

class App extends React.Component{
    constructor() {
        super();
        this.state={
            collapsed: false,
            units: true,
            baseMap: 'streets',
            zoomVal: 15,
            data: [],
            value: undefined,
            showPopup: false
        }
    }

    handleSearch = value => {
        if(value){
            fetch(value, data => this.setState({data}));
        } else {
            this.setState({data: []});
        }
    }

    handleChange = value => {
        this.setState({value});
        this.setState({showPopup: true})
        this.initMap();
    }

    toggle = () => {this.setState({collapsed: !this.state.collapsed})}

    componentDidMount(){this.initMap()}
    initMap(){
        const _self = this;
        const options = {url: 'https://js.arcgis.com/4.17/', css: 'https://js.arcgis.com/4.17/esri/css/main.css'};

        esriLoader.loadModules([
            "esri/Map",
            "esri/views/MapView",
            "esri/widgets/ScaleBar",
            "esri/widgets/Compass",
            "esri/widgets/Track",
            "esri/widgets/Search"
        ], options).then(
            ([
                 //modules
                 Map, MapView, ScaleBar, Compass, Track, Search
             ]) => {
                //codeHere
                let map = new Map({
                    basemap: _self.state.baseMap
                });

                // eslint-disable-next-line no-unused-vars
                let view = new MapView({
                    container: "viewDiv",
                    map: map,
                    center: lonlat,
                    zoom: _self.state.zoomVal
                })
                let scaleBar = new ScaleBar({
                    view: view,
                    unit: _self.state.units ? "metric" : "non-metric"
                });
                let compass = new Compass({
                    view: view,
                })
                let track = new Track({
                    view: view
                });
                let searchWidget = new Search({
                    view: view
                });
                view.ui.add([
                    {component: scaleBar, position: "bottom-left", index: 0},
                    {component: compass, position: "top-left", index: 3},
                    {component: track, position: "top-left", index: 1},
                    {component: searchWidget, position: "top-right", index: 0}
                ]);
                if (_self.state.showPopup) {
                    view.popup.open({title: currentValue, location: view.center, content: "Reverse geocode: " + lonlat})
                }
                view.watch("zoom", ()=>{if(view.zoom % 1 === 0){_self.setState({zoomVal: view.zoom})}})
            }
        ).catch(err => {
            console.error('地图初始化失败', err)
        })
    }

    changeBasemap(e){
        this.setState({
            baseMap: e.target.value
        }, this.initMap)
    }

    changeUnits(){
        this.setState({
            units: !this.state.units
        },this.initMap)
    }

    changeZoom = value => {
        this.setState({
            zoomVal: value,
        });
    };

    refresh(){
        this.initMap();
    }

    render(){
        const {baseMap, zoomVal} = this.state;
        const options = this.state.data.map(d => <Option key={d.value}>{d.text}</Option>);
        return(
            <div className="App">
                <Layout>
                    <Sider id='components-layout-custom-trigger' trigger={null} collapsible collapsed={this.state.collapsed}>
                        <div className="logo" />
                        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                            <Menu.Item key="1" icon={<UserOutlined />}>
                                功能1
                            </Menu.Item>
                            <Menu.Item key="2" icon={<VideoCameraOutlined />}>
                                功能2
                            </Menu.Item>
                            <Menu.Item key="3" icon={<UploadOutlined />}>
                                功能3
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Layout className="site-layout">
                        <Header className="site-layout-background" style={{ padding: 0 }}>
                            {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: this.toggle,
                            })}
                        </Header>
                        <Content className="site-layout-background" style={{margin: '24px 16px', padding: 24,}}>
                            <Row>
                                <Col flex={11}>
                                    <div id='viewDiv'/>
                                </Col>
                                <Col flex={1}>
                                    <span>用React重写ArcGIS Api for JS部分功能</span><br /><br />
                                    <span>搜索功能</span><br/><br/>
                                    <Select showSearch value={this.state.value} placeholder="input search text" style={{ width: 200 }} defaultActiveFirstOption={false} showArrow={false} filterOption={false} onSearch={this.handleSearch} onChange={this.handleChange} notFoundContent={null}>
                                        {options}
                                    </Select>< br/><br />
                                    <span>改变比例尺单位</span><br /><br />
                                    <Button onClick={this.changeUnits.bind(this)}>{this.state.units? "公制" : "英制"}</Button><br /><br />
                                    <span>修改地图类型</span><br /><br />
                                    <Radio.Group options={basemapOptions} onChange={this.changeBasemap.bind(this)} value={baseMap} optionType="button"/><br /><br />
                                    <Row>
                                        <Col span={12}>
                                            <span>拉滑条改变缩放</span><br /><br />
                                            <Slider
                                                min={1}
                                                max={20}
                                                onChange={this.changeZoom}
                                                onAfterChange={this.refresh.bind(this)}
                                                value={typeof zoomVal === 'number' ? zoomVal : 1}
                                            />
                                            <br />
                                            <Button onClick={this.refresh.bind(this)}>改变缩放</Button>
                                        </Col>
                                        <Col span={4}>
                                            <br /><br />
                                            <InputNumber
                                                min={1}
                                                max={20}
                                                style={{ margin: '0 16px' }}
                                                value={zoomVal}
                                                onChange={this.changeZoom}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div id='viewDiv'/>
                        </Content>
                        <Footer style={{ textAlign: 'center',padding: '0',margin: '0'}}><p>Created by AntD React ArcGIS API for JavaScript</p> <p>Author: 201813890017 李浩辰</p></Footer>
                    </Layout>
                </Layout>
            </div>
        );
    }
}

export default App;
